<template>
  <div>
    <h3>Email</h3>
    <input
      v-model.trim="userPayload.email"
      class="border-2 p-2 rounded-xl w-11/12"
      :class="
        v$.userPayload.email.$error === true
          ? 'border-red'
          : primaryEmailError
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="email"
      placeholder="Email"
    />
    <p v-if="v$.userPayload.email.$error === true" class="text-red text-sm">
      Email not valid
    </p>
    <p v-if="primaryEmailError" class="text-red text-sm">Email taken!</p>
  </div>
  <div>
    <h3>Mobile Number</h3>
    <input
      v-model.number="userPayload.mobile_phone"
      class="border-2 p-2 rounded-xl w-11/12"
      :class="
        v$.userPayload.mobile_phone.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="number"
      placeholder="Mobile Number"
    />
    <p
      v-if="v$.userPayload.mobile_phone.$error === true"
      class="text-red text-sm"
    >
      Required 11-15 Digits
    </p>
  </div>
  <div>
    <h3>First Name</h3>
    <input
      v-model.trim="userPayload.first_name"
      max="10"
      class="border-2 p-2 rounded-xl w-11/12"
      :class="
        v$.userPayload.first_name.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="First Name"
    />
  </div>
  <div>
    <h3>Last Name</h3>
    <input
      v-model.trim="userPayload.last_name"
      class="border-2 p-2 rounded-xl w-11/12"
      :class="
        v$.userPayload.last_name.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Last Name"
    />
  </div>
  <div class="grid col-span-2">
    <h3>Gender</h3>
    <v-select
      v-model="userPayload.gender"
      name="siteName"
      class="style-chooser-gender"
      :class="
        v$.userPayload.gender.$error === true
          ? 'style-chooser-gender-error'
          : 'style-chooser-gender'
      "
      placeholder="Please Select"
      label="customLabel"
      :options="dropdownData"
    ></v-select>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, numeric, minLength, maxLength } from "@vuelidate/validators";
export default {
  props: {
    primaryEmailError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      userPayload: {
        email: "",
        mobile_phone: "",
        first_name: "",
        last_name: "",
        gender: "",
      },
      dropdownData: ["Male", "Female"],
    };
  },
  validations() {
    return {
      userPayload: {
        email: { required, email },
        mobile_phone: { required, numeric, minLengthValue: minLength(11), maxLengthValue: maxLength(15)},
        first_name: { required, maxLengthValue: maxLength(50) },
        last_name: { required, maxLengthValue: maxLength(50) },
        gender: { required },
      },
    };
  },
  watch: {
    primaryEmailError() {
      return console.log("@hcanged: ", this.primaryEmailError);
    },
  },
};
</script>

<style>
.style-chooser-gender.vs__dropdown-toggle,
.style-chooser-gender .vs__dropdown-menu {
  border: 2px solid #425563;
  height: 5rem;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.style-chooser-gender .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
.style-chooser-gender .vs__clear,
.style-chooser-gender .vs__open-indicator {
  fill: black;
}
.style-chooser-gender-error .vs__dropdown-toggle,
.style-chooser-gender-error .vs__dropdown-menu {
  border: 2px solid red;
}
</style>
