<template>
  <div>
    <h3>Professional Email</h3>
    <input
      v-model.trim="profilePayload.professional_email"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.professional_email.$error === true
          ? 'border-red'
          : professionalEmailError
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="email"
      placeholder="Email"
    />
    <p
      v-if="v$.profilePayload.professional_email.$error === true"
      class="text-red text-sm"
    >
      Email not valid
    </p>
    <p v-if="professionalEmailError" class="text-red text-sm">Email taken!</p>
  </div>
  <div>
    <h3>Middle Name</h3>
    <input
      v-model.trim="profilePayload.middle_name"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.middle_name.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'Smith'"
    />
  </div>
  <div>
    <h3>Maiden Name</h3>
    <input
      v-model.trim="profilePayload.maiden_name"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.maiden_name.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'Doe'"
    />
  </div>
  <div>
    <h3>Work Number</h3>
    <input
      v-model.number="profilePayload.work_phone"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.work_phone.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="number"
      placeholder="Work Number"
    />
    <p
      v-if="v$.profilePayload.work_phone.$error === true"
      class="text-red text-sm"
    >
      Required 11 Digits
    </p>
  </div>
  <div>
    <h3>Home Telphone</h3>
    <input
      v-model.number="profilePayload.home_phone"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.home_phone.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="number"
      placeholder="Home Telephone Number"
    />
    <p
      v-if="v$.profilePayload.home_phone.$error === true"
      class="text-red text-sm"
    >
      Required 11 Digits
    </p>
  </div>
  <div>
    <h3>D.O.B</h3>
    <input
      v-model="profilePayload.dob"
      placeholder="yyyy-MM-dd"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.dob.$error === true ? 'border-red' : 'border-darkGrey'
      "
      type="date"
    />
  </div>
  <div>
    <h3>Address Line 1</h3>
    <input
      v-model.trim="profilePayload.address_line_1"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.address_line_1.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'Main Street'"
    />
  </div>
  <div>
    <h3>Address Line 2</h3>
    <input
      v-model.trim="profilePayload.address_line_2"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.address_line_2.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'Main Town'"
    />
  </div>
  <div>
    <h3>City</h3>
    <input
      v-model.trim="profilePayload.city"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.city.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'London'"
    />
  </div>
  <div>
    <h3>County</h3>
    <input
      v-model.trim="profilePayload.county"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.county.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'Northamptonshire'"
    />
  </div>
  <div>
    <h3>Country</h3>
    <input
      v-model.trim="profilePayload.country"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.country.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'United Kingdom'"
    />
  </div>
  <div>
    <h3>Postal Code</h3>
    <input
      v-model.trim="profilePayload.zip_code"
      class="border-2 p-2 rounded-xl w-full"
      :class="
        v$.profilePayload.zip_code.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'AB99 9CD'"
    />
  </div>
  <div>
    <h3>NHS Card</h3>
    <v-select
      v-model="profilePayload.nhs_card"
      name="siteName"
      class="style-chooser-nhs-card"
      :class="
        v$.profilePayload.nhs_card.$error === true
          ? 'style-chooser-nhs-card-error'
          : 'style-chooser-nhs-card'
      "
      placeholder="Please Select"
      label="customLabel"
      :options="dropdownData"
    ></v-select>
  </div>
  <div>
    <h3>NHS Number</h3>
    <input
      v-model.trim="profilePayload.nhs_number"
      class="border-2 p-2 rounded-xl w-full"
      :disabled="profilePayload.nhs_card === 'Yes' ? false : true"
      :class="
        v$.profilePayload.nhs_number.$error === true
          ? 'border-red'
          : 'border-darkGrey'
      "
      type="text"
      placeholder="Eg. 'NHS123F456'"
    />
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredIf,
} from "@vuelidate/validators";
export default {
  props: {
    professionalEmailError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      profilePayload: {
        professional_email: "",
        middle_name: "",
        maiden_name: "",
        work_phone: "",
        home_phone: "",
        dob: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        county: "",
        country: "",
        zip_code: "",
        nhs_card: "",
        nhs_number: null,
      },
      dropdownData: ["Yes", "Pending", "No"],
    };
  },
  validations() {
    return {
      profilePayload: {
        professional_email: { required, email },
        middle_name: { required, maxLengthValue: maxLength(50) },
        maiden_name: { required, maxLengthValue: maxLength(50) },
        work_phone: { required, numeric, minLengthValue: minLength(11) },
        home_phone: { required, numeric, minLengthValue: minLength(11) },
        dob: { required },
        address_line_1: { required, maxLengthValue: maxLength(200) },
        address_line_2: { required, maxLengthValue: maxLength(200) },
        city: { required, maxLengthValue: maxLength(50) },
        county: { required, maxLengthValue: maxLength(50) },
        country: { required, maxLengthValue: maxLength(50) },
        zip_code: { required, maxLengthValue: maxLength(50) },
        nhs_card: { required },
        nhs_number: {
          required: requiredIf(function () {
            return this.profilePayload.nhs_card === "Yes";
          }),
        },
      },
    };
  },
};
</script>

<style>
.style-chooser-nhs-card .vs__dropdown-toggle,
.style-chooser-nhs-card .vs__dropdown-menu {
  border: 2px solid #425563;
  height: 5rem;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.style-chooser-nhs-card .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
.style-chooser-nhs-card .vs__clear,
.style-chooser-nhs-card .vs__open-indicator {
  fill: black;
}
.style-chooser-nhs-card-error .vs__dropdown-toggle,
.style-chooser-nhs-card-error .vs__dropdown-menu {
  border: 2px solid red;
}
</style>
