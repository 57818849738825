<template>
  <div
    v-if="!isLoading"
    class="flex flex-col justify-center items-center pt-3"
    :class="heightClass"
  >
    <div class="flex justify-center items-center rounded-xl" :class="mainClass">
      <div class="grid grid-cols-2 w-3/4 gap-5">
        <div class="grid col-span-2">
          <h1 class="font-semibold">Create a new user</h1>
        </div>
        <create-user-form-part-one
          :primaryEmailError="primaryEmailError"
          v-if="formPart === 1"
          ref="firstForm"
        />
        <create-user-form-part-two
          :professionalEmailError="professionalEmailError"
          v-if="formPart === 2"
          ref="secondForm"
        />
        <div class="grid col-span-2 place-self-center">
          <div class="grid w-full h-1 grid-cols-6 gap-4 mt-3">
            <div
              class="
                h-full
                col-span-3
                rounded
                cursor-pointer
                transform-gpu
                hover:scale-110
                duration-300
              "
              :class="formPart === 1 ? 'bg-teal' : 'bg-lightGrey'"
              @click="formPart = 1"
            ></div>
            <div
              class="
                h-full
                col-span-3
                rounded
                cursor-pointer
                transform-gpu
                hover:scale-110
                duration-300
              "
              :class="formPart === 2 ? 'bg-teal' : 'bg-lightGrey'"
              @click="formPart = 2"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="formPart == 1">
      <base-button @click="switchForm(2)">Next</base-button>
    </div> -->
    <!-- <div>
      <base-button @click="createUser">Create User</base-button>
    </div> -->
    <base-button
      class="mt-2"
      @click="createUser"
      :animateButton="animateButton"
    >
      Create User
    </base-button>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import createUserFormPartOne from "./createUserFormPartOne.vue";
import createUserFormPartTwo from "./createUserFormPartTwo.vue";
import moment from "moment";
export default {
  props: {
    mainClass: {
      type: String,
      default: "w-1/2 shadow-2xl p-10",
    },
    heightClass: {
      type: String,
      default: "h-full",
    },
  },
  components: { createUserFormPartOne, createUserFormPartTwo },
  async mounted() {
    if (this.$store.getters["HQnewHire/getHiringRequest"] === null) {
      await this.fetchHiringRequest(this.$route.params.manageVacancy);
      console.log(this.$store.getters["HQnewHire/getHiringRequest"]);
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      formPart: 1,
      isLoading: false,
      primaryEmailError: false,
      professionalEmailError: false,
      animateButton: false,
      createUserPayload: {
        email: "",
        first_name: "",
        last_name: "",
        is_candidate: 1,
        gender: "",
        mobile_phone: "",
        job_title: "",
        contract_type: "",
        contract_start_date: "",
        contracted_hours_per_week: "",
        hiring_request: this.$route.params.manageVacancy,
        department: "",
      },
      updateProfilePayload: {
        profile: null,
        first_name: "",
        last_name: "",
        professional_email: "test@test.com",
        middle_name: "",
        maiden_name: "",
        gender: "",
        work_phone: "",
        home_phone: "",
        mobile_phone: "",
        dob: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        county: "",
        country: "",
        zip_code: "",
        nhs_card: "",
        nhs_number: "",
      },
    };
  },

  methods: {
    async createUser() {
      this.v$.$validate();
      if (!this.v$.$error && this.formPart === 1) {
        //Data not from Hiring Request
        this.createUserPayload.job_title = this.getHiringRequest.job_title;
        this.createUserPayload.contract_type =
          this.getHiringRequest.contract_type;
        this.createUserPayload.contract_start_date = this.convertedTime(
          this.getHiringRequest.created_at
        );
        this.createUserPayload.department = this.getHiringRequest.department.id;
        this.createUserPayload.contracted_hours_per_week =
          this.getHiringRequest.work_patterns[0].id.toString();

        //Merged data from hiring request & first form
        this.createUserPayload = {
          ...this.createUserPayload,
          ...this.$refs.firstForm.userPayload,
        };

        this.createUserPayload.mobile_phone =
          this.createUserPayload.mobile_phone.toString();

        // RUN this to make sure the validation state is reset before new form shown
        this.v$.$reset();
        this.formPart = 2;
        return;
      }
      if (this.formPart === 2) {
        if (!this.v$.$error) {
          // Need this data for ednpoint to work (comes from earlier form)
          this.updateProfilePayload.first_name =
            this.createUserPayload.first_name;
          this.updateProfilePayload.last_name =
            this.createUserPayload.last_name;
          this.updateProfilePayload.mobile_phone =
            this.createUserPayload.mobile_phone.toString();

          this.updateProfilePayload.gender = this.createUserPayload.gender;
          this.updateProfilePayload = {
            ...this.updateProfilePayload,
            ...this.$refs.secondForm.profilePayload,
          };
          this.updateProfilePayload.work_phone =
            this.updateProfilePayload.work_phone.toString();
          this.updateProfilePayload.home_phone =
            this.updateProfilePayload.home_phone.toString();

          try {
            if (!this.professionalEmailError) {
              this.animateButton = true;
              this.createUserPayload.hiring_request =
                this.$route.params.manageVacancy || this.getHiringRequest?.id;
              await this.$store.dispatch(
                "REnewHire/recruiterCreateUser",
                this.createUserPayload
              );
              this.updateProfilePayload.profile = this.getNewUserProfileId;
              await this.$store.dispatch(
                "REnewHire/recruiterUpdateProfile",
                this.updateProfilePayload
              );
              this.$emit("profile_created");
            }

            this.$router.push({
              name: "re-manage-vacancy",
              params: { manageVacancy: this.$route.params.manageVacancy },
              query: { success: "true" },
            });
          } catch (error) {
            if (error.message === "The email has already been taken.") {
              this.primaryEmailError = true;
              this.formPart = 1;
            } else if (
              error.message === "The professional email has already been taken."
            ) {
              this.professionalEmailError = true;
              this.formPart = 2;
            }
          }
        }
      }
    },
    async fetchHiringRequest(requestFormId) {
      try {
        await this.$store.dispatch("HQnewHire/fetchHiringRequest", {
          hiring_request: requestFormId,
        });
        if (this.getHiringRequest && this.getHiringRequest.status) {
          this.payload.status = this.getHiringRequest.status;
        }
        if (this.getHiringRequest && this.getHiringRequest.decision_reason) {
          this.payload.decision_reason = this.getHiringRequest.decision_reason;
        }
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    convertedTime(data) {
      return moment(data).format("YYYY-MM-DD");
    },
  },
  computed: {
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },
    getNewUserProfileId() {
      return this.$store.getters["REnewHire/getNewUserProfileId"];
    },
  },
};
</script>
